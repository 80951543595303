const Module = () => import("./Module.vue");
import store from "@/state/store";

const moduleRoute = {
  path: "/tramitador",
  name: "tramitador",
  component: Module,
  children: [
    {
      path: "",
      name: "tramitador.default",
      meta: {
        title: "Inicio",
      },
      component: () => import("@/views/Default.vue"),
    },
    {
      path: "change/password",
      name: "tramitador.change.password",
      meta: {
        title: "Cambiar contraseña",
      },
      component: () => import("./views/ChangePassword.vue"),
    },
    {
      path: "profile",
      name: "tramitador.profile",
      meta: {
        title: "Perfil",
      },
      component: () => import("./views/Profile.vue"),
    },
    {
      path: "tramites/curso",
      name: "tramitador.lista.tramites.curso",
      meta: {
        title: "Trámites en curso",
      },
      component: () => import("./views/TramitesCurso.vue"),
    },
    {
      path: "tramites/comunicacion/:slug",
      name: "tramitador.comunicacion.tramites.curso",
      meta: {
        title: "Comunicación interna y externa",
      },
      component: () => import("./views/ComunicacionTramitador.vue"),
    },
    {
      path: "tramites/editar/:slug",
      name: "tramitador.editar.tramites.curso",
      meta: {
        title: "Trámite Plantilla Editar",
      },
      component: () =>
        import("./views/tramite-plantillas/TramitePlantillaEditar.vue"),
    },
    {
      path: "veterinario/guias-traslado",
      name: "tramitador.lists.guias.traslado",
      meta: {
        title: "Guías de traslado",
      },
      component: () => import("./views/GuiasTraslado.vue"),
    },
    {
      path: "reporte/traslado/economico",
      name: "reporte.economico.traslado.tramitador",
      meta: {
        title: "Reporte Economico Traslado",
      },
      component: () => import("./views/reportes/ReporteEconomicoTrasladoVeterinarioExterno.vue"),
    },
    {
      path: "veterinario/editar/:id_solicitud_tramite",
      name: "veterinario.editar.tramites.curso",
      meta: {
        title: "Trámite Plantilla Editar Veterinario",
      },
      component: () =>
          import("./views/tramite-plantillas/TramitePlantillaEditarVeterinario.vue"),
    },
  ],
  meta: {
    beforeResolve(routeTo, routeFrom, next) {
      if (store.getters["authfack/isSenasagUser"])
        return next({ name: "senasag.default" });
      if (
        store.getters["authfack/isExternalUser"] &&
        store.getters["authfack/isCompanyUser"]
      )
        return next({ name: "empresa.default" });
      if (
        store.getters["authfack/isExternalUser"] &&
        store.getters["authfack/isProcessorUser"]
      )
        return next();
      console.log("You are neither external user nor sia user");
      return false;
    },
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
