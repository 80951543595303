export const saveSessionUser = (session) => {
  try {
    let user = JSON.parse(localStorage.getItem("user"));
    user.session = session;
    localStorage.setItem("user", JSON.stringify(user));
  }
  catch (error) {
    return null;
  }
}

export const saveTokenAccess = (accessToken) => {
  localStorage.setItem("access_token", String(accessToken));
};

export const clearToken = () => {
  localStorage.removeItem("access_token");
};

export const saveTokenRefresh = (refreshToken) => {
  localStorage.setItem("refresh_token", String(refreshToken));
}

export const clearTokenRefresh = () => {
  localStorage.removeItem("refresh_token");
}

export const saveTokenExpireTime = (expireTime) => {
  localStorage.setItem("expires_in", expireTime);
}

export const clearTokenExpireTime = () => {
  localStorage.removeItem("expires_in");
}

export const getTokenRefresh = () => {
  try {
    return localStorage.getItem("refresh_token");
  }
  catch (error) {
    return null;
  }
}

export const getTokenExpireTime = () => {
  try {
    return localStorage.getItem("expires_in");
  }
  catch (error) {
    return -1;
  }
}

export const getTokenAccess = () => {
  try {
    return localStorage.getItem("access_token");
  }
  catch (error) {
    let keysToRemove = ["distrital", "user", "provincias", "municipios", 'access_token', 'refresh_token', 'expires_in'];
    keysToRemove.forEach((k) => localStorage.removeItem(k));
    return null;
  }
}

export const timeToExpireToken = () => {
  let expireTime = getTokenExpireTime() * 1000;
  let now = new Date();
  return expireTime - now.getTime();
}

export const getToken = () => {
  try {
    return getTokenAccess();
  } catch (error) {
    let keysToRemove = ["distrital", "user", "provincias", "municipios"];
    keysToRemove.forEach((k) => localStorage.removeItem(k));
    return null;
  }
};

export const getUserStored = () => {
  return JSON.parse(localStorage.getItem("user"));
};
