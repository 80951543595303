import { i } from "vue3-pdfjs/esm/index-19f89c12";
import { authHeader } from "./auth-header";
import {getTokenRefresh, saveTokenAccess, saveTokenExpireTime, saveTokenRefresh} from "@/services/token";

export const userService = {
  login,
  loginSia,
  logout,
  register,
  getAll,
};

const hostname = process.env.VUE_APP_PROD_URL;

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Accept-Language": "es-BO" },
    body: JSON.stringify({ user: email, username: email, password }),
  };
  return fetch(`${hostname}api/v1/auth-user/login/`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      console.log(res);
      // login successful if there's a jwt token in the response
      if (res.session) {
        //christian quisbert 28/03/2024 para controlar las sesiones
        const currentDate = new Date();
        localStorage.setItem("loginTime", currentDate.toString());
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(res));
        saveTokenAccess(res.session.access_token);
        saveTokenRefresh(res.session.refresh_token);
        saveTokenExpireTime(res.session.expires_in);
      }
      return res;
    });
}

function loginSia(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Accept-Language": "es-BO" },
    body: JSON.stringify({ user: email, username: email, password }),
  };
  return fetch(`${hostname}api/v1/auth-user/login-sia/`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      // login successful if there's a jwt token in the response
      if (res.session) {
        //christian quisbert 28/03/2024 para controlar las sesiones
        const currentDate = new Date();
        localStorage.setItem("loginTime", currentDate.toString());
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(res));
        saveTokenAccess(res.session.access_token);
        saveTokenRefresh(res.session.refresh_token);
        saveTokenExpireTime(res.session.expires_in);
      }
      return res;
    });
}

function  logout() {
  if (!getTokenRefresh()) return;
  const logoutClient = fetch(`${hostname}api/v1/auth-user/logout/`, {
    method: "POST",
    headers: { "Content-Type": "application/json", "Accept-Language": "es-BO" },
    body: JSON.stringify({ refresh: getTokenRefresh() }),
  });
    Promise.all([logoutClient]).then(() => {
      let keysToRemove = ["distrital", "user", "provincias", "municipios", 'access_token', 'refresh_token', 'expires_in'];
      keysToRemove.forEach((k) => localStorage.removeItem(k));
    }
  );
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(`/users/register`, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(`/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      let error = null;
      if (response.status === 401 || response.status == 400) {
        error = "Credenciales incorrectas.";
        logout();
      }
      if (response.status === 404) error = "Esta cuenta no existe";
      return Promise.reject(data);
    }
    return data;
  });
}
