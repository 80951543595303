import Vuex from 'vuex'
const cambiarEstado = new Vuex.Store({
    state: {
        miVariable: localStorage.setItem('miVariable','Valor inicial')
    },
    mutations: {
        cambiarVariable(state, nuevoValor) {
            state.miVariable = nuevoValor;
            localStorage.setItem('miVariable', nuevoValor);
        }
    }
})

export default cambiarEstado;