import {
  getTokenExpireTime,
  getTokenRefresh,
  getUserStored,
  saveSessionUser,
  saveTokenAccess,
  saveTokenExpireTime,
  saveTokenRefresh,
  timeToExpireToken,
} from "@/services/token";
import store from "@/state/store";

const host = process.env.VUE_APP_PROD_URL;

export const refreshToken = async () => {
  if (!getTokenRefresh()) return Promise.resolve("No hay token para refrescar");
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "Accept-Language": "es-BO" },
    body: JSON.stringify({ refresh: getTokenRefresh() }),
  };
  if (getUserStored() && getTokenExpireTime() && timeToExpireToken() < 0) {
    let response = await fetch(`${host}api/v1/auth-user/refresh-token/`, requestOptions)
    if (response.status === 200) {
      let res = await response.json();
      saveTokenAccess(res.access_token);
      saveTokenRefresh(res.refresh_token);
      saveTokenExpireTime(res.expires_in);
      saveSessionUser(res);
      return Promise.resolve("Token refrescado");
    }
    else {
      await store.dispatch("authfack/logout");
      await window.location.reload();
      return Promise.resolve("No se pudo refrescar el token y se cerro la sesion");
    }
  }
  return Promise.resolve("Esperando a que el token expire");
};